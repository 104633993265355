import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="Polypropylen (PP)" description="@todo" mdxType="SEO" />
    <h1 {...{
      "id": "polypropylen-pp"
    }}>{`Polypropylen (PP)`}</h1>
    <p>{`Polypropylen (PP) je termoplastický polymer ze skupiny polyolefinů, které patří mezi nejběžnější plasty, používá se v mnoha odvětvích potravinářského a textilního průmyslu a v laboratorních vybaveních.`}</p>
    <h2 {...{
      "id": "vlastnosti-polypropylenu"
    }}>{`Vlastnosti polypropylenu`}</h2>
    <p>{`Polypropylen má podobné fyzikálně-chemické vlastnosti jako `}<a parentName="p" {...{
        "href": "/technologie/materialy/polyethylen-pe/"
      }}>{`polyethylen`}</a>{`. Křehne při nízkých teplotách, kolem 140-150 stupňů Celsia měkne, kolem 160-170 stupňů Celsia se taví. Je odolný vůči olejům, organickým rozpouštědlům a alkoholům, dobře se rozpouští v xylenech či tetrahydronaftalenu. Polypropylen vyniká velmi dobrou chemickou a mechanickou odolností.`}</p>
    <h2 {...{
      "id": "použití-polypropylenu"
    }}>{`Použití polypropylenu`}</h2>
    <p>{`Příklad použití polypropylenu pro obaly
Polypropylen je často používán pro výrobu lan a provazů kvůli své nízké hustotě hmotnosti - lana jsou pak dostatečně lehká, takže můžou plavat na hladině.`}</p>
    <p>{`Polypropylen se také užívá jako alternativa k polyvinylchloridu (PVC) pro izolaci elektrických kabelů v málo větraných prostředích, především v tunelech. Polypropylen totiž při hoření neprodukuje tolik kouře a žádné toxické halogenované uhlovodíky, které by mohly za vysokých teplot přispět ke vzniku různých kyselin.`}</p>
    <p>{`Zdroj: `}<a parentName="p" {...{
        "href": "https://cs.wikipedia.org/wiki/Polypropylen",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`Polypropylen na Wikipedii`}</a>{`, licence `}<a parentName="p" {...{
        "href": "https://creativecommons.org/licenses/by-sa/3.0/deed.cs",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`CC BY-SA 3.0`}</a></p>
    <hr />
    <a href="javascript:history.back()">Zpět</a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      